import { useSound } from "@vueuse/sound";

export default defineNuxtPlugin({
  name: "Init Vendor Store",
  dependsOn: ["Setup Firebase"],
  parallel: true,
  hooks: {
    "app:mounted"() {
      const { isVendor } = storeToRefs(useUser());
      const { ordersRefreshInterval } = useAppConfig();

      // Init Vendor store  if user is a vendor
      whenever(
        isVendor,
        () => {
          // Refresh orders at specified interval
          const store = useVendor();
          setInterval(() => {
            store.refreshOrders();
          }, ordersRefreshInterval);

          const { play } = useSound("/sounds/new.mp3");
          const { setToast } = useToast();

          // Check for new orders and play notification
          const { orders } = storeToRefs(store);
          watch(orders, (orders, oldOrders) => {
            const a = orders.filter(({ status }) => status === "PAID");
            const b = oldOrders.filter(({ status }) => status === "PAID");

            if (a.length > b.length) {
              setToast({
                title: "New order",
                message: "You have a new order!",
                type: "success",
              });
              play();
            }
          });
        },
        { once: true, immediate: true }
      );
    },
  },
});
