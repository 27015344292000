import { default as _91vendor_45id_93mR8JWp4GK9Meta } from "/home/runner/work/delivery-doves/delivery-doves/pages/[vendor-id].vue?macro=true";
import { default as accountLemfhfoMA7Meta } from "/home/runner/work/delivery-doves/delivery-doves/pages/account.vue?macro=true";
import { default as bulk_45addNCV6dXz2twMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/admin/bulk-add.vue?macro=true";
import { default as controller_45managerFSpwfh3AKuMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/admin/controller-manager.vue?macro=true";
import { default as customersPBgUEwF6JzMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/admin/customers.vue?macro=true";
import { default as driver_45managerFVculkWm7AMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/admin/driver-manager.vue?macro=true";
import { default as indexmeSr68qHLmMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/admin/index.vue?macro=true";
import { default as orderszFqLqyItGHMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/admin/orders.vue?macro=true";
import { default as platform_45managerXJy1HemjqUMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/admin/platform-manager.vue?macro=true";
import { default as review_45vendormcZtD98vu0Meta } from "/home/runner/work/delivery-doves/delivery-doves/pages/admin/review-vendor.vue?macro=true";
import { default as vendor_45managerXZoLOdTIAOMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/admin/vendor-manager.vue?macro=true";
import { default as analyticsnQMNhRrm5QMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/controller/analytics.vue?macro=true";
import { default as driver_45managerRJb9i7xouHMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/controller/driver-manager.vue?macro=true";
import { default as indexLqYd19tEhkMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/controller/index.vue?macro=true";
import { default as job_45managerIUbZQiHNaCMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/controller/job-manager.vue?macro=true";
import { default as indexDShGmN0KLJMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/driver/index.vue?macro=true";
import { default as sign_45up_45formbSJVg76WUNMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/driver/sign-up-form.vue?macro=true";
import { default as sign_45upYs5QU79SwEMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/driver/sign-up.vue?macro=true";
import { default as forgot_45passwordOYTQpXZdQJMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/forgot-password.vue?macro=true";
import { default as indexZBdoi9r4u4Meta } from "/home/runner/work/delivery-doves/delivery-doves/pages/index.vue?macro=true";
import { default as onboarding6mGsQGeU2YMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/onboarding.vue?macro=true";
import { default as _91id_935giFI6mjO5Meta } from "/home/runner/work/delivery-doves/delivery-doves/pages/order/[id].vue?macro=true";
import { default as cartRNC3Qu5eSPMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/order/cart.vue?macro=true";
import { default as _91_46_46_46slug_93dAGg1E5Tw3Meta } from "/home/runner/work/delivery-doves/delivery-doves/pages/policies/[...slug].vue?macro=true";
import { default as indexAB5MyVFAC3Meta } from "/home/runner/work/delivery-doves/delivery-doves/pages/policies/index.vue?macro=true";
import { default as sign_45inXsIqZUJQSVMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/sign-in.vue?macro=true";
import { default as sign_45upj3AimFqoeOMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/sign-up.vue?macro=true";
import { default as support122TtNKTXLMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/support.vue?macro=true";
import { default as testuIM3NppRgBMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/test.vue?macro=true";
import { default as analytics47bmD4qm7YMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/vendor/analytics.vue?macro=true";
import { default as indexouTD6AegfFMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/vendor/index.vue?macro=true";
import { default as _91menu_45id_93aRFB7ZthKnMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/vendor/menu-manager/[menu-id].vue?macro=true";
import { default as offers_45manager1OnEk8KhZ2Meta } from "/home/runner/work/delivery-doves/delivery-doves/pages/vendor/offers-manager.vue?macro=true";
import { default as order_45manageradhkSDKiddMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/vendor/order-manager.vue?macro=true";
import { default as sign_45up_45formhymQMk9XtsMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/vendor/sign-up-form.vue?macro=true";
import { default as sign_45upEckkZOqRBHMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/vendor/sign-up.vue?macro=true";
import { default as verificationUCNNh2unjgMeta } from "/home/runner/work/delivery-doves/delivery-doves/pages/verification.vue?macro=true";
import { default as component_45stubwYAmC0TE65Meta } from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k5julp47co42mccqzmxjjgbkk4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubwYAmC0TE65 } from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k5julp47co42mccqzmxjjgbkk4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "vendorid",
    path: "/:vendorid()",
    meta: _91vendor_45id_93mR8JWp4GK9Meta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/[vendor-id].vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: accountLemfhfoMA7Meta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "admin-bulk-add",
    path: "/admin/bulk-add",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/admin/bulk-add.vue").then(m => m.default || m)
  },
  {
    name: "admin-controller-manager",
    path: "/admin/controller-manager",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/admin/controller-manager.vue").then(m => m.default || m)
  },
  {
    name: "admin-customers",
    path: "/admin/customers",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/admin/customers.vue").then(m => m.default || m)
  },
  {
    name: "admin-driver-manager",
    path: "/admin/driver-manager",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/admin/driver-manager.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-orders",
    path: "/admin/orders",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/admin/orders.vue").then(m => m.default || m)
  },
  {
    name: "admin-platform-manager",
    path: "/admin/platform-manager",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/admin/platform-manager.vue").then(m => m.default || m)
  },
  {
    name: "admin-review-vendor",
    path: "/admin/review-vendor",
    meta: review_45vendormcZtD98vu0Meta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/admin/review-vendor.vue").then(m => m.default || m)
  },
  {
    name: "admin-vendor-manager",
    path: "/admin/vendor-manager",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/admin/vendor-manager.vue").then(m => m.default || m)
  },
  {
    name: "controller-analytics",
    path: "/controller/analytics",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/controller/analytics.vue").then(m => m.default || m)
  },
  {
    name: "controller-driver-manager",
    path: "/controller/driver-manager",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/controller/driver-manager.vue").then(m => m.default || m)
  },
  {
    name: "controller",
    path: "/controller",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/controller/index.vue").then(m => m.default || m)
  },
  {
    name: "controller-job-manager",
    path: "/controller/job-manager",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/controller/job-manager.vue").then(m => m.default || m)
  },
  {
    name: "driver",
    path: "/driver",
    meta: indexDShGmN0KLJMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/driver/index.vue").then(m => m.default || m)
  },
  {
    name: "driver-sign-up-form",
    path: "/driver/sign-up-form",
    meta: sign_45up_45formbSJVg76WUNMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/driver/sign-up-form.vue").then(m => m.default || m)
  },
  {
    name: "driver-sign-up",
    path: "/driver/sign-up",
    meta: sign_45upYs5QU79SwEMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/driver/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordOYTQpXZdQJMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboarding6mGsQGeU2YMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "order-id",
    path: "/order/:id()",
    meta: _91id_935giFI6mjO5Meta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "order-cart",
    path: "/order/cart",
    meta: cartRNC3Qu5eSPMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/order/cart.vue").then(m => m.default || m)
  },
  {
    name: "policies-slug",
    path: "/policies/:slug(.*)*",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/policies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "policies",
    path: "/policies",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/policies/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45inXsIqZUJQSVMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upj3AimFqoeOMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    meta: testuIM3NppRgBMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "vendor-analytics",
    path: "/vendor/analytics",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/vendor/analytics.vue").then(m => m.default || m)
  },
  {
    name: "vendor",
    path: "/vendor",
    meta: indexouTD6AegfFMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/vendor/index.vue").then(m => m.default || m)
  },
  {
    name: "vendor-menu-manager-menuid",
    path: "/vendor/menu-manager/:menuid()",
    meta: _91menu_45id_93aRFB7ZthKnMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/vendor/menu-manager/[menu-id].vue").then(m => m.default || m)
  },
  {
    name: "vendor-offers-manager",
    path: "/vendor/offers-manager",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/vendor/offers-manager.vue").then(m => m.default || m)
  },
  {
    name: "vendor-order-manager",
    path: "/vendor/order-manager",
    meta: order_45manageradhkSDKiddMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/vendor/order-manager.vue").then(m => m.default || m)
  },
  {
    name: "vendor-sign-up-form",
    path: "/vendor/sign-up-form",
    meta: sign_45up_45formhymQMk9XtsMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/vendor/sign-up-form.vue").then(m => m.default || m)
  },
  {
    name: "vendor-sign-up",
    path: "/vendor/sign-up",
    meta: sign_45upEckkZOqRBHMeta || {},
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/vendor/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "verification",
    path: "/verification",
    component: () => import("/home/runner/work/delivery-doves/delivery-doves/pages/verification.vue").then(m => m.default || m)
  },
  {
    name: component_45stubwYAmC0TE65Meta?.name,
    path: "/login",
    component: component_45stubwYAmC0TE65
  },
  {
    name: component_45stubwYAmC0TE65Meta?.name,
    path: "/register",
    component: component_45stubwYAmC0TE65
  },
  {
    name: component_45stubwYAmC0TE65Meta?.name,
    path: "/vendor/register",
    component: component_45stubwYAmC0TE65
  },
  {
    name: component_45stubwYAmC0TE65Meta?.name,
    path: "/driver/register",
    component: component_45stubwYAmC0TE65
  }
]