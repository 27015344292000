import {
  type CollectionReference,
  type DocumentReference,
  type DocumentData,
  doc,
  collection,
  where,
  query,
} from "firebase/firestore";

/** Internal function to create a typed collection reference */
function collectionRef<
  AppModelType = DocumentData,
  DbModelType extends DocumentData = DocumentData,
>(path: string) {
  const { $firebaseFirestore } = useNuxtApp();
  return collection($firebaseFirestore, path) as CollectionReference<AppModelType, DbModelType>;
}

/** Get a {@link DocumentReference} for the current user's {@link UserMeta} */
export const currentUserRef = computed(() => userMetaRef(useUser().uid));

/**
 * Vendors that are visibile to the public
 * - Must be approved
 * - Must have an island
 */
export const publicVendors = () =>
  query(
    collectionRef<Vendor>("vendors"),
    where("approved", "==", true),
    where("island", "in", islands)
  );

/** Get a {@link DocumentReference} for {@link UserMeta} */
export const userMetaRef = (uid: MaybeRefOrGetter<string | undefined>) => {
  const id = toValue(uid);
  if (!id) return null;
  return doc(collectionRef<UserMeta>("user_meta"), id);
};

/** Get a {@link DocumentReference} for a {@link Vendor} */
export const vendorRef = (vendorId: MaybeRefOrGetter<string | undefined>) => {
  const id = toValue(vendorId);
  if (!id) return null;
  return doc(collectionRef<Vendor>("vendors"), id);
};

/** Get a {@link DocumentReference} for a {@link Menu} */
export const menuRef = (
  vendorId: MaybeRefOrGetter<string | undefined>,
  menuId: MaybeRefOrGetter<string | undefined>
) => {
  const vendor = vendorRef(vendorId);
  const id = toValue(menuId);
  if (!id || !vendor) return null;
  return doc(vendor, "menus", id) as DocumentReference<Menu>;
};

/** Get a {@link DocumentReference} for a {@link Customer} */
export const customerRef = (customerId: MaybeRefOrGetter<string | undefined>) => {
  const id = toValue(customerId);
  if (!id) return null;
  return doc(collectionRef<Customer>("customers"), id);
};

/** Get a {@link DocumentReference} for a {@link Driver} */
export const driverRef = (driverId: MaybeRefOrGetter<string>) => {
  const id = toValue(driverId);
  if (!id) return null;
  return doc(collectionRef<Driver>("drivers"), id);
};

/** Get a {@link DocumentReference} for an {@link Order} */
export const orderRef = (orderId: MaybeRefOrGetter<string>) => {
  const id = toValue(orderId);
  if (!id) return null;
  return doc(collectionRef<Order>("orders"), id);
};
