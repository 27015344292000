import { defineStore, skipHydrate } from "pinia";
import { collection, doc, query, where, type DocumentReference } from "firebase/firestore";
import { ref as storRef } from "firebase/storage";

export const useVendor = defineStore("vendor", () => {
  const { $firebaseFirestore, $firebaseStorage, $authFetch } = skipHydrate(useNuxtApp());
  const { uid } = skipHydrate(storeToRefs(useUser()));

  const vendorRef = computed(() => {
    if (uid.value && $firebaseFirestore)
      return doc($firebaseFirestore, "vendors", uid.value) as DocumentReference<Vendor>;
  });
  const storageRef = computed(() => {
    if (uid.value && $firebaseStorage) return storRef($firebaseStorage, `vendors/${uid.value}`);
  });

  const vendor = useFirestore(vendorRef);

  const orderCountQuery = computed(() => {
    if (uid.value && $firebaseFirestore)
      return query(collection($firebaseFirestore, "orders"), where("vendor.id", "==", uid.value));
  });
  const orderList = useFirestore(orderCountQuery);
  const orderCount = computed(() => orderList.value?.length ?? 0);
  watch(orderCount, (newCount, oldCount) => {
    if (newCount !== oldCount) refreshOrders();
  });

  // Get the vendor's orders
  const { data: orders, refresh: refreshOrders } = skipHydrate(
    useAsyncData("vendor-orders", () => $authFetch<Order[]>(`/api/vendor/${uid.value}/orders`), {
      default: () => [],
      server: false,
      watch: [uid],
    })
  );

  return { vendor, vendorRef, storageRef, orders, refreshOrders, orderCount };
});
