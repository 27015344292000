import { initializeApp, type FirebaseOptions } from "@firebase/app";
import type {} from "firebase/app";
import { connectAuthEmulator, getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getPerformance } from "firebase/performance";
import { env } from "std-env";
import { destr } from "destr";
import type { UserRecord } from "firebase-admin/auth";

declare global {
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

/**
 * This is the client side Firebase plugin.
 * It is used to initialize the Firebase SDK and to setup up anything we may need on the frontend.
 *
 */
export default defineNuxtPlugin({
  name: "Setup Firebase",
  dependsOn: ["Authenticated Fetch"],
  setup({ $config }) {
    if (import.meta.dev)
      self.FIREBASE_APPCHECK_DEBUG_TOKEN =
        ($config.public.firebaseAppcheckDebugToken as string) ?? true;
    const { recaptchaSiteKey } = useAppConfig();
    const config = destr<FirebaseOptions>(useRuntimeConfig().public.firebaseConfig);

    // Setup Firebase
    const app = initializeApp(config);
    const auth = getAuth(app);
    auth.useDeviceLanguage();
    const firestore = getFirestore(app);
    const storage = getStorage(app);

    if (env.FIREBASE_EMULATORS === "true") {
      console.log("🔥 Using Firebase Emulators");
      connectAuthEmulator(auth, "http://127.0.0.1:9099", {
        disableWarnings: true,
      });
      connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
      connectStorageEmulator(storage, "127.0.0.1", 9199);
    }

    // Setup App Check
    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(recaptchaSiteKey),
    });

    // Subscribe to auth state changes
    const userStore = useUser();
    const { $authFetch } = useNuxtApp();
    onAuthStateChanged(auth, async (user) => {
      userStore.client = user;
      if (user) {
        const name = user.isAnonymous ? "Anonymous" : (user.displayName ?? "user");
        console.log("👤 Welcome,", name);
        try {
          if (!userStore.server)
            userStore.server = await $authFetch<UserRecord>("/api/current-user-token");
        } catch (e) {
          signOutUser();
          await navigateTo("/sign-in");
        }
      } else {
        console.log("👤 Not logged in");
        userStore.server = null;
        useCart().clearCart();
      }
    });

    // Setup Firebase Performance Monitoring
    // const perf = getPerformance(app);

    return {
      provide: {
        firebaseApp: app,
        firebaseAuth: auth,
        firebaseFirestore: firestore,
        firebaseStorage: storage,
        firebaseAppCheck: appCheck,
        // firebasePerformance: perf,
      },
    };
  },
});

declare module "#app" {
  interface NuxtApp {
    $firebaseApp: ReturnType<typeof initializeApp>;
    $firebaseAuth: ReturnType<typeof getAuth>;
    $firebaseFirestore: ReturnType<typeof getFirestore>;
    $firebaseStorage: ReturnType<typeof getStorage>;
    $firebaseAppCheck: ReturnType<typeof initializeAppCheck>;
    // $firebasePerformance: ReturnType<typeof getPerformance>;
  }
}
