import { defineStore, skipHydrate } from "pinia";
import { set, get } from "@vueuse/core";

export const useCart = defineStore("cart", () => {
  const { setToast } = skipHydrate(useToast());

  const vendor = useCookie<Vendor["id"]>("vendor", {
    maxAge: 86400,
    watch: "shallow",
    decode(value) {
      return String(value);
    },
  });
  const cart = useCookie<Cart>("cart", {
    maxAge: 86400,
    watch: true,
    default: () => [],
  });
  const total = computed(() => cart.value.reduce((acc, item) => acc + item.price, 0));

  const addToCart = (vendorId: Vendor["id"], item: MenuItem, quantity: number) => {
    if (get(vendor) && get(vendor) !== vendorId) clearCart();
    set(vendor, vendorId);
    setToast({
      title: "Cart updated",
      message: `${quantity}x ${item.name} added to cart`,
      type: "success",
    });
    for (let i = 0; i < quantity; i++) cart.value.push(item);
  };

  const removeFromCart = (index: number) => {
    cart.value.splice(index, 1);
    if (cart.value.length === 0) set(vendor, undefined);
  };

  const clearCart = () => {
    console.warn("Clearing cart");
    if (cart.value.length > 0)
      setToast({
        title: "Cart cleared",
        message: "Your cart has been cleared",
        type: "warning",
      });
    set(vendor, undefined);
    set(cart, []);
  };

  return {
    vendor,
    cart,
    total,
    addToCart,
    removeFromCart,
    clearCart,
  };
});
