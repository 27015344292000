import { useSound } from "@vueuse/sound";

export default defineNuxtPlugin({
  name: "Init Driver Store",
  dependsOn: ["Setup Firebase"],
  parallel: true,
  hooks: {
    "app:mounted"() {
      const { isDriver } = storeToRefs(useUser());
      const { ordersRefreshInterval } = useAppConfig();

      // Init Driver store if user is a driver
      whenever(
        isDriver,
        () => {
          // Refresh orders at specified interval
          const store = useDriver();
          setInterval(() => {
            store.refreshOrders();
          }, ordersRefreshInterval);

          const { play } = useSound("/sounds/new.mp3");
          const { setToast } = useToast();

          // Check for new orders and play notification
          const { orders } = storeToRefs(store);
          watch(orders, (orders, oldOrders) => {
            if (orders.length > oldOrders.length) {
              setToast({
                title: "New order",
                message: "You have a new order!",
                type: "success",
              });
              play();
            }
          });
        },
        { once: true, immediate: true }
      );
    },
  },
});
