import revive_payload_client_qbykiTAkxg from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k5julp47co42mccqzmxjjgbkk4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XNSbLeWXKJ from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k5julp47co42mccqzmxjjgbkk4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QvTsdeIflB from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k5julp47co42mccqzmxjjgbkk4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_DvgCB1sOfC from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.17.1_sass@1.55_nomh7clvpkq6fzqpha7dtjk65q/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_hMjSDLjcgZ from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k5julp47co42mccqzmxjjgbkk4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_aGwF8sQBRS from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k5julp47co42mccqzmxjjgbkk4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GuSYh72jiH from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k5julp47co42mccqzmxjjgbkk4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_RZzwdL1HyU from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k5julp47co42mccqzmxjjgbkk4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_DuKqPAIdyW from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.22.4_typescript@5.6.3_vue@3.5.8_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/delivery-doves/delivery-doves/.nuxt/components.plugin.mjs";
import prefetch_client_8itIL4bdej from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.2_k5julp47co42mccqzmxjjgbkk4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_JMBAD7reRt from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxt+icon@1.5.8_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.17.1_sass@1.55.0_ters_p2g7fryi6acgs44kiwyyse4u3u/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import preview_client_BvoMXQEvZW from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxthq+studio@2.1.1_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import titles_uEPRGAcXn6 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.12.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.17.1_fpenxufmplnkfihe6k63gvbi44/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_0qTa3QqP20 from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.17.1_sass@1_zvuwx3tyc2dmiywo67kdqf22wm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_k6U3GQeJvK from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.17.1_sass@1_zvuwx3tyc2dmiywo67kdqf22wm/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_GTEI21z5YM from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/nuxt-csurf@1.6.3_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import sounds_GJGTybOsxS from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@vueuse+sound@2.0.1_vue@3.5.8_typescript@5.6.3_/node_modules/@vueuse/sound/dist/runtime/plugins/sounds.ts";
import authenticated_fetch_client_kRaJ5FV55P from "/home/runner/work/delivery-doves/delivery-doves/plugins/authenticated-fetch.client.ts";
import firebase_client_zXNDa0wxFH from "/home/runner/work/delivery-doves/delivery-doves/plugins/firebase.client.ts";
import init_driver_store_client_bQc6iNNq6D from "/home/runner/work/delivery-doves/delivery-doves/plugins/init-driver-store.client.ts";
import init_vendor_store_client_9hhcheX78g from "/home/runner/work/delivery-doves/delivery-doves/plugins/init-vendor-store.client.ts";
import vee_validate_client_P40JVl0wNI from "/home/runner/work/delivery-doves/delivery-doves/plugins/vee-validate.client.ts";
import defaults_0dpijdq20J from "/home/runner/work/delivery-doves/delivery-doves/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.12.0_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@20.17.1_fpenxufmplnkfihe6k63gvbi44/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_qbykiTAkxg,
  unhead_XNSbLeWXKJ,
  router_QvTsdeIflB,
  _0_siteConfig_DvgCB1sOfC,
  payload_client_hMjSDLjcgZ,
  navigation_repaint_client_aGwF8sQBRS,
  check_outdated_build_client_GuSYh72jiH,
  chunk_reload_client_RZzwdL1HyU,
  plugin_vue3_DuKqPAIdyW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8itIL4bdej,
  plugin_JMBAD7reRt,
  preview_client_BvoMXQEvZW,
  titles_uEPRGAcXn6,
  siteConfig_0qTa3QqP20,
  inferSeoMetaPlugin_k6U3GQeJvK,
  plugin_GTEI21z5YM,
  sounds_GJGTybOsxS,
  authenticated_fetch_client_kRaJ5FV55P,
  firebase_client_zXNDa0wxFH,
  init_driver_store_client_bQc6iNNq6D,
  init_vendor_store_client_9hhcheX78g,
  vee_validate_client_P40JVl0wNI,
  defaults_0dpijdq20J
]