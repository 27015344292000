export default {
  "/sounds/new.mp3": {
    "src": "/sounds/new.mp3",
    "volume": 1
  },
  "new": {
    "src": "/sounds/new.mp3",
    "format": [
      "mp3"
    ]
  }
}