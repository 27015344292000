import {
  onAuthStateChanged,
  type User,
  signOut,
  getIdToken,
  getIdTokenResult,
} from "@firebase/auth";

export const getCurrentUser = async (): Promise<User | null> => {
  if (import.meta.server) return null;
  const { $firebaseAuth } = useNuxtApp();
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      $firebaseAuth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
};

export const getToken = async (): Promise<string> => {
  const user = await getCurrentUser();
  if (user) return await getIdToken(user, true);
  return "";
};

export const signOutUser = async () => {
  const { $firebaseAuth } = useNuxtApp();
  return await signOut($firebaseAuth);
};
